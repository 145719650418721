@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;400;500&display=swap');

body {
  overflow-x: hidden;
  max-width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, footer {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'NewYork';
    src: url("./theme/fonts/NewYork.otf") format("opentype");
}

@font-face {
    font-family: 'Milkman';
    src: url('./theme/fonts/MilkmanRegular.woff2') format('woff2'),
        url('./theme/fonts/MilkmanRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Angelica';
    src: url('./theme/fonts/AngelicaRegular.woff2') format('woff2'),
        url('./theme/fonts/AngelicaRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

li {
  list-style: none;
}

body {
  background-color: #F9F8F7;
}

body, div, p, section, h1, h2, h3, h4, h5, span, ul, li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #121212;
}